<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">课程参数</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{stu == 'edit'? '修改':'新增'}}课程参数</a>
        </span>
      </div>
      <div class="framePage-body addClassSubject">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="flexcc">
              <el-form ref="Form" :model="Form" :rules="rules" label-width="13rem">
                <el-form-item label="行政区划" prop="areaId" class="form-item">
                  <el-input
                    v-if="Form.areaId == '-1000'"
                    v-model="areaName"
                    disabled
                    style="width:250px"
                  />
                  <el-cascader
                    v-else
                    v-model="Form.areaId"
                    style="width:250px"
                    :options="areatreeList"
                    :props="propsarea"
                    clearable
                    size="small"
                  ></el-cascader>
                </el-form-item>
                <el-form-item label="培训类型" prop="trainTypeId" class="form-item">
                  <el-input
                    v-if="Form.trainTypeId == '-1000'"
                    v-model="trainTypeName"
                    disabled
                    style="width:250px"
                  />
                  <el-cascader
                    v-else
                    style="width:250px"
                    size="small"
                    :props="{emitPath:false,value:'id',label:'label',children:'children',checkStrictly: true}"
                    v-model="Form.trainTypeId"
                    :options="trainTypeList"
                    clearable
                    @change="handleTrainType"
                  ></el-cascader>
                </el-form-item>
              </el-form>
            </div>
            <div class="bottom-btn">
              <el-button
                @click="cancle"
                class="bgc-bv"
              >{{Form.trainTypeId != '-1000' && Form.areaId != '-1000' ? '取消': '返回'}}</el-button>
              <el-button
                @click="seveData('ruleForm')"
                class="bgc-bv"
                v-show="Form.trainTypeId != '-1000' && Form.areaId != '-1000'"
              >保存</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadPic from "@/mixins/UploadPic";
import DatePicker from "@/mixins/DatePicker";
// import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
export default {
  name: "operate/realNameAuthenticationListInfo",
  components: {
    // tree
  },
  mixins: [UploadPic, DatePicker],
  data() {
    return {
      // 列表页传过来的id
      id: "",
      // 页面数据
      Form: {
        areaId: "", // 行政区划
        trainTypeId: "" // 培训类型
      },
      // 行政区划
      areatreeList: [],
      //培训类型
      datatreeList: [],
      // 行政区划的数据格式
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
      // 培训类型
      trainTypeList: [],
      // 数据校验
      rules: {
        // 行政区划 - 校验
        areaId: [
          { required: true, message: "请选择行政区划", trigger: "change" }
        ],
        // 行政区划 - 培训类型
        trainTypeId: [
          { required: true, message: "请选择培训类型", trigger: "change" }
        ]
      }
    };
  },
  created() {
    this.id = this.$route.query.paraId;
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getDetail(this.id);
    } else {
      this.Form.paraTimeLimit.push({ ...this.Form.obj });
    }
  },
  methods: {
    // 获取培训类型
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then(res => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    // 点击确定 - 有id编辑/无id添加
    doAjax() {
      let parmar = {
        areaId: this.Form.areaId,
        trainTypeId: this.Form.trainTypeId
      };
    },
    // 返回
    cancle() {
      this.$router.back();
    },
    getDetail(paraId) {
      this.$post("/run/project/course/param/query", { paraId }).then(res => {
        if (res.status == "0") {
          this.Form = {
            ...res.data,
            //  新增的
            paraIsPopQuestion: res.data.paraIsPopQuestion || false,
            paraPopNum: res.data.paraPopNum || "10",
            paraPopNum: res.data.paraPopNum || "3",
            //
            paraTimeLimit: JSON.parse(res.data.paraTimeLimit || "[]"),
            studyLength: res.data.paraTimeLimit !== "[{}]" ? true : false,
            paraExamHint: res.data.paraExamHint,
            paraCompleteCondition: res.data.paraCompleteCondition
              ? true
              : false,
            paraCompleteConditionNum: res.data.paraCompleteCondition
              ? res.data.paraCompleteCondition
              : "80"
          };

          // console.log(this.Form);
          if (!this.Form.paraTimeLimit.length) {
            this.Form.paraTimeLimit.push({ ...this.Form.obj });
          }
          this.areaName = "默认";
          this.trainTypeName = "默认";
        }
      });
    }
  }
};
</script>
<style lang="less" >
.addClassSubject {
  .el-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .form-item {
      width: 100%;
      padding-left: 10rem;
    }
    .padd0 {
      padding-left: 0;
    }
  }

  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-textarea {
    .el-textarea__inner {
      resize: none;
    }
  }
  .paraCompleteConditionInput {
    display: inline-block;
    .el-input {
      display: inline-block;
      width: 40% !important;
      margin: 0 10px;
      height: 32px;
    }
  }
}
</style>
